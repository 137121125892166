<!--
 * @Author: lisushuang
 * @Date: 2021-10-14 11:20:15
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 15:18:59
 * @Description: 应用管理界面
 * @FilePath: /dwh_front/src/views/objects/apps.vue
-->
<!-- eslint-disable -->
<template>
  <div class="full" v-loading="loading">
    <el-row class="h-full">
      <el-col class="h-full bd-right p-5" :span="4">
        <leftItem
          delAble
          topTitle="应用管理"
          :defaultRow="defaultRow"
          :tableData="listData"
          field="name"
          @row-del="rowDel"
          @row-click="rowClick"
          @top-add="
            showModal = true;
            isEdit = false;
          "
        />
      </el-col>
      <el-col :span="10" class="h-full p-5" v-if="nowInfo">
        <div class="h-full scroller overflow-y-auto">
          <h1 class="mb-5">
            应用名称：{{ nowInfo.name }}
            <el-button class="float-right" type="primary" @click="prepareEdit"
              >编辑</el-button
            >
            <el-button class="float-right" style="margin-right:10px" type="danger" @click="fullMigrate"
              >整体迁移</el-button
            >
          </h1>
          <p class="mb-5">
            <span class="type-label-before-blue">应用描述：</span> {{ nowInfo.description }}
          </p>
          <p class="mb-5">
            <span class="type-label-before-blue">应用分类：</span> {{ nowInfo.category }}
          </p>

          <p class="type-label-before-blue">
            应用页面：共({{ nowInfo.pages_info.length }})个
          </p>
          <div
            class="mb-5"
            style="padding-left: 40px"
            v-for="(item, index) in nowInfo.pages_info"
            :key="'page' + index"
          >
            {{ item.name }}
            <el-button
              size="mini"
              class="float-right"
              style="margin-right: 5px"
              @click="$router.push({path:'/dwh/objects/pageEditor',query:{id:item.id}})"
              >查看</el-button
            >
            <el-button class="float-right" style="margin-right:5px" size="mini" type="danger" @click="metaMigratePage(item.uuid)"
              >迁移</el-button
            >
          </div>
        </div>
      </el-col>
      <el-col :span="10" class="h-full p-5" v-if="nowInfo">
        <p class="type-label-before-blue">
          应用对象：共({{ nowInfo.objects_info.length }})个
        </p>
        <div style="overflow-y: scroll;height:calc(100% - 50px);width:100%">
          <div
            class="mb-5"
            style="padding-left: 10px"
            v-for="(item, index) in nowInfo.objects_info"
            :key="index"
          >
            <p>{{ item.name }} - {{ item.code }}</p>
            <el-button
              style="margin-top: 10px"
              size="mini"
              @click="
                $router.push({
                  path: '/dwh/objects/detail',
                  query: {
                    obuuid: item.uuid,
                    obname: item.name,
                    type: 'field',
                  },
                })
              "
              type="primary"
              >管理</el-button
            >
            <el-button size="mini" type="danger" @click="metaMigrateObject(item.uuid)"
              >迁移</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>

    <b-modal
      ref="elmodal"
      mode="drawer"
      :title="isEdit ? '编辑应用' : '新增应用'"
      v-if="showModal"
      :show="showModal"
      @sure="sure"
      @cancel="showModal = false"
    >
      <div slot="content">
        <!-- <p class="type-label">基本信息</p> -->
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="应用名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="应用描述" prop="description">
            <el-input v-model="form.description"></el-input>
          </el-form-item>
          <el-form-item label="应用分类" prop="category">
            <el-input v-model="form.category"></el-input>
          </el-form-item>
          <b-select
            :selectData="objectData"
            :multiple="true"
            label="数据对象"
            :required="false"
            required
            @on-change="getFormObjects"
            :backSelected="form != null ? form.objects : ''"
          />
          <b-select
            :selectData="pagesData"
            :multiple="true"
            label="页面对象"
            :required="false"
            @on-change="getFormPages"
            keyField="uuid"
            :backSelected="form != null ? form.pages : ''"
          />
        </el-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import leftItem from "@/components/leftItem.vue";
import objects from "@/api/objects";
import obOpMixin from "./objectOperationMixin.js"
import { collect } from 'collect.js';
import MetaMigrate from "../../global/actions/MetaMigrate";


export default {
  components: { leftItem },
  mixins:[obOpMixin],
  data() {
    return {
      nowAppId: 0,
      originApps: "",
      listData: [],
      showModal: false,
      form: {
        name: "",
        description: "",
        category: "",
        objects: "",
        pages: "",
      },
      rules: {},
      isEdit: false,
      pagesData: [],
      objectData: [],
      nowInfo: null,
      loading: false,
      defaultRow: "",
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        description: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  watch: {
    "$route.query.id": {
      handler(data) {
        if (!data || data == 0) {
          this.nowInfo = null;
          this.nowAppId = 0;
          return;
        }
        this.getDetail(data);
      },
    },
    showModal(val) {
      if (!val) {
        this.form = {
          name: "",
          description: "",
          category: "",
          objects: "",
          pages: "",
        };
      }
    },
  },
  methods: {
    metaMigratePage(uuid){
      this.$globalAction('MetaMigrate', {type: 'page', uuid: uuid})
    },
    metaMigrateObject(uuid){
      this.$globalAction('MetaMigrate', {type: 'object', uuid: uuid})
    },
    fullMigrate(){
      this.$globalAction('MetaMigrate',{type:'sys_app',uuid:this.nowAppId})
    },
    rowDel(data) {
      objects.apps.deleteApp(data.id).then((res) => {
        if (res.data.code == 200) {
          this.freshData("del");
          this.rowClick({ id: 0 });
        }
      });
    },
    prepareEdit() {
      this.isEdit = true;
      let data = JSON.parse(JSON.stringify(this.nowInfo));
      this.form = {
        name: data.name,
        description: data.description,
        category: data.category,
        objects: JSON.parse(data.objects),
        pages: JSON.parse(data.pages),
      };

      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    rowClick(data) {
      // this.loading = true
      if (this.$route.query.id == data.id) {
        return;
      }
      this.$router.replace({ path: "/dwh/objects/apps", query: { id: data.id } });
    },
    sure() {
      if (!this.form.name || !this.form.description || !this.form.objects) {
        this.$message.error("请填写完整！");
        this.$refs.elmodal.loading = false;
        return false;
      }

      let data = {
        name: this.form.name,
        description: this.form.description,
        category: this.form.category,
        objects:
          this.form.objects && this.form.objects.length
            ? this.form.objects
            : [],
        pages: this.form.pages && this.form.pages.length ? this.form.pages : [],
      };
      this.loading = true;
      if (this.isEdit) {
        objects.apps.editApp(this.nowInfo.id, data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.showModal = false;
            this.freshData();
            this.getDetail(this.nowAppId);
          }
        });
      } else {
        objects.apps.createApp(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.showModal = false;
            this.freshData();
          }
        });
      }
    },
    freshData() {
      this.loading = true;
      objects.apps.getApps().then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.listData = res.data.data;
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.length != 0
          ) {
            this.listData = res.data.data;

            if (!this.$route.query.id) {
              this.defaultRow = res.data.data[0];
            } else {
              let one = this.listData.filter((item) => {
                return item.id == this.$route.query.id;
              });
              if(one.length){
                this.defaultRow = one[0]
              }else{
                this.defaultRow = res.data.data[0];
              }
            }

            this.loading = false;
          } else {
            this.listData = [];
            this.nowInfo = null;
            this.loading = false;
          }
        }
      });
    },
    getFormObjects(data) {
      if (this.isEdit && (!data || data.length == 0)) {
        return;
      }
      this.form.objects = data;
    },
    getFormPages(data) {
      if (this.isEdit && (!data || data.length == 0)) {
        return;
      }
      this.form.pages = data;
    },

    /**
     * @description: 本方法已改为同步 返回一个Promise
     * @param {String} id 传入的数据ID
     * @return {Promise}
     */
    async getDetail(id) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.nowAppId = id;
        objects.apps
          .getAppInfo(id)
          .then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.nowInfo = res.data.data;
              resolve();
            }
            reject();
          })
          .catch((res) => {
            reject();
          });
      });
    },
  },

  mounted() {
    let id = this.$route.query.id;
    if (id && id != 0) {
      this.nowAppId = id
      this.getDetail(id).then(res=>{
      this.freshData();

      })
      // this.rowClick({id:this.nowAppId})
    } else {
      this.nowInfo = null;
      this.freshData();
    }

    objects.getAll(1, 2000).then((res) => {
      if (res.data.code == 200) {
        this.objectData = res.data.data.data;
      }
    });

    objects.apps.getPages().then((res) => {
      if (res.data.code == 200) {
        this.pagesData = res.data.data;
      }
    });
  },
};
</script>

<style scoped lang="less">
</style>
