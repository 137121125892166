/*
 * @LastEditTime: 2022-08-05 14:55:59
 * @Description: 元数据迁移
 * @FilePath: /dataview-next/src/global/actions/MetaMigrate.js
 * @Date: 2022-04-29 16:54:02
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import baseAction from "./baseAction";
import object from "@/api/objects";
import app from "../../main";
import {Message, MessageBox} from "element-ui";
import http from "../http"


export default class MetaMigrate extends baseAction {

    constructor() {
        super()
    }


    handler() {
        console.log('MetaMigrate', this.props)
        if (!this.props || !this.props.type || !this.props.uuid) {
            Message.error("MetaMigrate：非法调用参数")
            return
        }

        const type = this.props.type
        const uuid = this.props.uuid
        const supportTypes = ['sys_app', 'object', 'object_data', 'page', 'menu']
        if (supportTypes.indexOf(type) === -1) {
            Message.error("MetaMigrate：不支持的迁移类型！")
        }

        this.chooseOneServer('迁移数据', type, uuid).then(target => {
            // 对象数据可能需要查询条件
            let method = 'migrate_' + type
            this[method](target, uuid)
        })
    }

    /**
     * @description 获取视图对象列表
     * @param uuid 对象uuid
     * @returns {Promise} resolve(serverInfo)
     */
    getViewsList(uuid) {
        return new Promise((reslove) => {
            object.getViewsList(uuid).then((res) => {
                if (res.data.code === 200 && res.data.data && res.data.data.data) {
                    reslove(res.data.data.data)
                }
            });
        })
    }

    /**
     * @description: 弹出服务器选择框
     * @param {String} doText 展示的数据
     * @return {Promise} resolve(serverInfo)
     */
    chooseOneServer(doText = "迁移数据", type, uuid) {
        return new Promise((resolve, reject) => {
            const h = app.$createElement
            const infos = this.solveProjectInfo()
            let value = ''
            let options = []
            let Options1 = [];
            let view = '';

            infos.forEach((item) => {
                options.push(h('el-option', {
                    props: {
                        label: item.name,
                        value: item
                    }
                }))
            })
            // 渲染的select选择服务器
            let ok = h('el-select', {
                props: {
                    value: value,
                    name: 'test'
                },
                on: {
                    input: (v) => {
                        ok.componentInstance.selected.currentLabel = v.name
                        value = v
                    }
                },
                style: {
                    width: '100%'
                }
            }, [options])

            if (type != 'object_data') {
                MessageBox({
                    title: "请选择服务器" + doText,
                    message: ok,
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            // instance.confirmButtonLoading = true;
                            // instance.confirmButtonText = '执行中...';
                            if (!value) {
                                Message.info('请选择一条数据')
                                return
                            }
                            instance = null
                            resolve(value)
                            done()
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                })
            } else {
                this.getViewsList(uuid).then(views => {
                    views.forEach((item) => {
                        Options1.push(h('el-option', {
                            props: {
                                label: item.name,
                                value: item
                            }
                        }))
                    })

                    let ok1 = h('el-select', {
                        props: {
                            value: view,
                            name: 'test2'
                        },
                        on: {
                            input: (v) => {
                                ok1.componentInstance.selected.currentLabel = v.name
                                view = v
                            }
                        },
                        style: {
                            'width': '100%',
                            'margin-top': '10px',
                        }
                    }, [Options1])

                    let ok2 = <div>
                        {ok}
                        {ok1}
                    </div>

                    MessageBox({
                        title: "请选择服务器" + doText,
                        message: ok2,
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                // instance.confirmButtonLoading = true;
                                // instance.confirmButtonText = '执行中...';
                                if (!value) {
                                    Message.info('请选择一条数据')
                                    return
                                }

                                if (!view) {
                                    Message.info('请选择一个视图')
                                    return
                                }

                                instance = null
                                value.view_uuid = view.uuid;
                                resolve(value)
                                done()
                            } else {
                                instance.confirmButtonLoading = false;
                                done();
                            }
                        }
                    })


                })

            }
        })
    }

    /**
     * @description: 解析本地projectInfos 为选择框数据
     * @param {Boolean} exceptNow 是否排除当前所在仓库
     * @return {Array} projectDatas
     */
    solveProjectInfo(exeptNow = false) {
        let info = localStorage.getItem('serverList', '[]')
        try {
            info = JSON.parse(info)
            let res = []
            info.forEach(item => {
                res.push({name: item.name, value: item})
            });
            return res
        } catch (e) {
            return []
        }
    }

    /**
     * @description: 迁移app
     * @param {Object} target 服务器信息
     * @param {String|Number} uuid app-id
     * @return {Void}
     */
    migrate_sys_app(target, uuid) {
        console.log(target.value)
        this.getLoading("数据量较大，请耐心等待...")
        http({
            url: 'app/migrate',
            method: "POST",
            data: {
                app_id: uuid,
                target_server: target.value.originURl,
                target_metadatatoken: target.value.metadatatoken
            }
        }).then(res => {
            if (res.data.code == 200) {
                Message.success('迁移成功')
            } else {
                if (res?.data?.msg) {
                } else {
                    Message.error("迁移失败，未知的错误")
                }
            }
            this.loading.close()
        }).catch(res => {
            this.loading.close()
            Message.error("迁移失败")
        })
    }

    /**
     * @description: 迁移object
     * @param {Object} target 服务器信息
     * @param {String|Number} uuid object-uuid
     * @return {Void}
     */
    migrate_object(target, uuid) {
        console.log(target.value)
        this.getLoading("数据量较大，请耐心等待...")
        http({
            url: 'object/migrate',
            method: "POST",
            data: {
                uuid: uuid,
                target_server: target.value.originURl,
                target_metadatatoken: target.value.metadatatoken
            }
        }).then(res => {
            if (res.data.code == 200) {
                Message.success('迁移成功')
            } else {
                if (res?.data?.msg) {
                } else {
                    Message.error("迁移失败，未知的错误")
                }
            }
            this.loading.close()
        }).catch(res => {
            this.loading.close()
            Message.error("迁移失败")
        })
    }

    /**
     * @description: 迁移object数据
     * @param {Object} target 服务器信息
     * @param {String|Number} uuid object-uuid
     * @param {String|Number} view_uuid 视图uuid
     * @return {Void}
     */
    migrate_object_data(target, uuid) {
        console.log(target.value)
        this.getLoading("数据量较大，请耐心等待...")
        http({
            url: 'objectdata/migrate',
            method: "POST",
            data: {
                uuid: uuid,
                view_uuid: target.view_uuid,
                target_server: target.value.originURl,
                target_metadatatoken: target.value.metadatatoken
            }
        }).then(res => {
            if (res.data.code == 200) {
                Message.success('迁移成功')
            } else {
                if (res?.data?.msg) {
                } else {
                    Message.error("迁移失败，未知的错误")
                }
            }
            this.loading.close()
        }).catch(res => {
            this.loading.close()
            Message.error("迁移失败")
        })
    }

    /**
     * @description: 迁移page
     * @param {Object} target 服务器信息
     * @param {String|Number} uuid object-uuid
     * @return {Void}
     */
    migrate_page(target, uuid) {
        console.log(target.value)
        this.getLoading("数据量较大，请耐心等待...")
        http({
            url: 'page/migrate',
            method: "POST",
            data: {
                page_uuid: uuid,
                target_server: target.value.originURl,
                target_metadatatoken: target.value.metadatatoken
            }
        }).then(res => {
            if (res.data.code == 200) {
                Message.success('迁移成功')
            } else {
                if (res?.data?.msg) {
                } else {
                    Message.error("迁移失败，未知的错误")
                }
            }
            this.loading.close()
        }).catch(res => {
            this.loading.close()
            Message.error("迁移失败")
        })
    }

    /**
     * @description: 迁移项目菜单
     * @param {Object} target 服务器信息
     * @param {String|Number} uuid object-uuid
     * @return {Void}
     */
    migrate_menu(target, uuid) {
        console.log(target.value)
        this.getLoading("数据量较大，请耐心等待...")
        http({
            url: 'menu/migrate',
            method: "POST",
            data: {
                parent_project_id: uuid,
                target_server: target.value.originURl,
                target_metadatatoken: target.value.metadatatoken
            }
        }).then(res => {
            if (res.data.code == 200) {
                Message.success('迁移成功')
            } else {
                if (res?.data?.msg) {
                } else {
                    Message.error("迁移失败，未知的错误")
                }
            }
            this.loading.close()
        }).catch(res => {
            this.loading.close()
            Message.error("迁移失败")
        })
    }

}
